import Certificate from "Integration/Components/Certificate";
import Integration from "Integration/Components/Integration";
import { Row, Space, Typography,Col } from "antd";

const IntegrationPage=()=>{
    return <div style={{margin:42}}>
        <Row align={'center'}>
            <Col xs={24} sm={12}  xxl={8}>
                <Integration/>
                <br></br>
                <Certificate/>
            </Col>
        </Row>
    </div>
}

export default IntegrationPage