import axios from "axios";
import { TOKEN_NAME } from "Constants";

const getToken = () => `Bearer ${localStorage.getItem(TOKEN_NAME)}`;

export const baseUrl = process.env.REACT_APP_BASE_URL;

const API = axios.create({
  baseURL: `${baseUrl}/`,
  headers: {
    "Content-Type": "application/json",
  },
});

API.interceptors.request.use(
  function (config) {
    if (localStorage.getItem(TOKEN_NAME)) {
      config.headers.common = {
        ...config.headers.common,
        authorization: getToken(),
      };
    }
    return config;
  },
  function (error) {
    console.log({ error });
    return Promise.reject(error);
  }
);

export default API;
