import { message } from "antd";
import { TOKEN_NAME } from "Constants";
import Ctx from "Context/Ctx";
import API from "Helpers/axiosApi";
import { useEffect, useState } from "react";
import { useJwt } from "react-jwt";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import {
  SESSION_STATE_INVALID,
  SESSION_STATE_LOADING,
  SESSION_STATE_VALID,
} from "./Constant";

const UNPROTECTED = ["/login", "/register", "/recovery-password"];

const Security = ({ children }) => {
  const { decodedToken } = useJwt(localStorage.getItem(TOKEN_NAME));
  const [modalBuyEvent, setModalBuyEvent] = useState(null);
  const [stateSession, setStateSession] = useState(SESSION_STATE_LOADING);
  const [user, setUser] = useState({});
  const [quota, setQuota] = useState(0);
  const location = useLocation();
  const navigation = useNavigate();

  const loadSession = () => {
    if (UNPROTECTED.includes(location.pathname)) {
      return;
    }
    setStateSession(SESSION_STATE_LOADING);

    if (!localStorage.getItem(TOKEN_NAME)) {
      setStateSession(SESSION_STATE_INVALID);
      return;
    } else {
      setStateSession(SESSION_STATE_VALID);
    }
  };

  const logout = async () => {
    localStorage.removeItem(TOKEN_NAME);
    setStateSession(SESSION_STATE_INVALID);
  };

  const login = (token) => {
    localStorage.setItem(TOKEN_NAME, token);
    setStateSession(SESSION_STATE_VALID);
  };

  useEffect(() => {
    loadSession();

    API.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        const status = error.response.status;
        if (status == 401) {
          logout();
          message.error("Su sesión ha caducado");
        }
        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    console.log({ decodedToken });
    setUser(decodedToken || {});
  }, [decodedToken]);

  useEffect(() => {
    if (stateSession == SESSION_STATE_INVALID) {
      //return navigation("/login");
    }
  }, [stateSession]);

  return (
    <Ctx.Provider
      value={{
        logout,
        login,
        stateSession,
        user,
        page: location.pathname,
        modalBuyEvent,
        setModalBuyEvent,
        quota,
        setQuota,
      }}
    >
      {children}
    </Ctx.Provider>
  );
};

export default Security;
