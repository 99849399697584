import { SOCKET_URL } from "Constants";
import { baseUrl } from "Helpers/axiosApi";
import { createContext, useContext, useEffect, useState } from "react";
import io from "socket.io-client";
import Ctx from "./Ctx";

const socket = io(SOCKET_URL);

export const SocketCtx = createContext();

const Socket = ({ children }) => {
  const { user, setQuota } = useContext(Ctx);
  const [socketEvent, setSocketEvent] = useState({});
  const room = user.role == 2 ? "admin" : user.owner;

  const join = () => {
    socket.off(room);
    socket.on(room, (data) => {
      setSocketEvent({ ...data, t: new Date().getTime() });
    });
  };

  useEffect(() => {
    if (user.role) {
      join();
    }
  }, [user]);

  useEffect(() => {
    if (setSocketEvent.action) {
      setSocketEvent({});
    }
  }, [setSocketEvent]);

  useEffect(() => {
    socket.connect();

    socket.on("connect", () => {
      console.log("Conectado");
      join();
    });

    socket.on("disconnect", () => {
      console.log("Desonectado");
    });

    return () => {
      console.log("Good bye");
      setSocketEvent({});
      socket.off(room);
      socket.off("connect");
      socket.off("disconnect");
      socket.disconnect();
    };
  }, []);

  return (
    <SocketCtx.Provider value={{ socketEvent, setSocketEvent }}>
      {children}
    </SocketCtx.Provider>
  );
};

export default Socket;
