import { notification } from "antd";
import API from "Helpers/axiosApi";
import React, { useContext, useEffect, useState } from "react";
import { SocketCtx } from "./Socket";

const EventCtx = React.createContext();

const EventProvider = ({ children }) => {
  const { socketEvent } = useContext(SocketCtx);
  const [events, setEvents] = useState([]);
  const [totalEvents, setTotalEvents] = useState(null);
  const [loading, setLoading] = useState(false);
  const [lastRecord, setLastRecord] = useState(null);
  const [filters, setFilters] = useState(null);
  const [t, setT] = useState(null);

  const load = () => {
    setLoading(true);
    API("event", {
      params: {
        lastRecord: lastRecord,
        total: totalEvents,
        ...(filters || {}),
      },
    })
      .then(({ data }) => {
        setEvents((prev) => [...prev, ...data.events]);
        setLastRecord(data.events.at(-1)?._id);
        setTotalEvents(data.total);
      })
      .finally(() => setLoading(false));
  };

  const clear = () => {
    setTotalEvents(null);
    setEvents([]);
    setLastRecord(null);
  };

  const reload = () => {
    clear();
    setT(new Date().getTime());
  };

  const onEventSaved = (evento) => {
    console.log("onEventSaved ");
    setEvents((prevItems) => {
      const existe = prevItems.find((e) => e._id == evento._id);
      if (existe) {
        return prevItems.map((e) => {
          if (e._id == evento._id) {
            return evento;
          }
          return e;
        });
      } else {
        console.log("Insert event");

        return [evento, ...prevItems];
      }
    });
  };

  useEffect(() => {
    if (
      socketEvent.action == "event-new" ||
      socketEvent.action == "event-update"
    ) {
      if (socketEvent.action == "event-new") {
        /*notification.success({
          message: "Evento " + socketEvent.event.code,
          description: "Registrado correctamente",
          duration: 5,
        });*/
      }
      if (socketEvent.action == "event-update") {
       /* notification.success({
          message: "Evento " + socketEvent.event.code,
          description: "Actualizado correctamente",
          duration: 5,
        });*/
      }
      onEventSaved(socketEvent.event);
    }
  }, [socketEvent]);

  useEffect(() => {
    if (filters) {
      reload();
    }
  }, [filters]);

  useEffect(() => {
    if (t) {
      load();
    }
  }, [t]);

  return (
    <EventCtx.Provider
      value={{
        load,
        reload,
        setEvents,
        events,
        loading,
        lastRecord,
        onEventSaved,
        filters,
        setFilters,
        totalEvents,
        clear,
      }}
    >
      {children}
    </EventCtx.Provider>
  );
};

export { EventCtx, EventProvider };
