import { Form ,Input,Upload, Button,Card,Typography, notification, Spin}from "antd"
import {PlusOutlined,FileDoneOutlined} from "@ant-design/icons"
import API from "Helpers/axiosApi";
import { useEffect, useState } from "react";

const Certificate=()=>{

    const [cargando,setCargando] = useState(false)
    const [creado,setCreado] = useState(false)

    const normFile = (e) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e?.fileList;
    };

    const beforeUpload=()=>{

        return false
    }

    const cargar=async ()=>{
        try{
            setCargando(true)
            const {data} = await API('integration/certificate')
            if(data.success){
                setCreado(true)
            }
        }catch(error){
            notification.open({
                type:'error',
                message:error?.data?.message || error.toString()
            })
        }finally{
            setCargando(false)
        }
    }

    const guardar=async (values)=>{
        try{
            setCargando(true)
            const form_data=new FormData()
            console.log(values.certificate[0].originFileObj)
            form_data.append('clave',values.certificate_password)
            form_data.append('archivo',values.certificate[0].originFileObj)

            const {data} = await API({url:'integration/certificate',data:form_data,method:'POST'})
            notification.open({
                type:'success',
                message:data?.message
            })
            cargar()
        }catch(error){
            setCargando(false)
            notification.open({
                type:'error',
                message:error?.data?.message || error.toString()
            })
        }
    }

    useEffect(()=>{
        cargar()
    },[])

    if(creado){
        return <Card>
            <center>
                <FileDoneOutlined style={{fontSize:96,color:'#D7DBDD'}}/>
                <br></br> <br></br>
            <Typography.Title level={4} style={{textAlign:'center'}}>Certificado cargado correctamente</Typography.Title>
            <Button danger onClick={()=>setCreado(false)}>Reemplazar</Button>
            </center>
        </Card>
    }

    return (
        <Spin spinning={cargando}>
            <Card>
                <center>
                <FileDoneOutlined style={{fontSize:96,color:'#D7DBDD'}}/>
                </center>
            <Form layout="vertical" onFinish={guardar}>
                    <Form.Item label="Clave del certificado" name="certificate_password" rules={[{required:true,message:'Ingrese la contraseña del certificado'}]}>
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item rules={[{required:true,message:'Seleccione su certificado P12'}]} label="Certificado" name="certificate" valuePropName="fileList" getValueFromEvent={normFile}>
                        <Upload maxCount={1} listType="picture-card" multiple={false} beforeUpload={beforeUpload}>
                        <div>
                        <PlusOutlined />
                        <div
                            style={{
                            marginTop: 8,
                            }}
                        >
                            Cargar .P12
                        </div>
                        </div>
                        </Upload>
                    </Form.Item>
                    <Button type="primary" htmlType="submit">Guardar Certificado</Button>
            </Form>
            </Card>
        </Spin>
    )
}

export default Certificate