import { Form, message, Modal, Slider, Statistic } from "antd";
import { useForm } from "antd/lib/form/Form";
import Ctx from "Context/Ctx";
import API from "Helpers/axiosApi";
import { createRef, useContext, useEffect, useRef, useState } from "react";

const marks = {
  50: "Basic",
  200: "Advance",
  800: "Expert",
};

const BuyEvents = () => {
  const modal = createRef();
  const [form] = useForm();
  const { modalBuyEvent, setModalBuyEvent } = useContext(Ctx);
  const [loading, setLoading] = useState(false);
  const [counter, setCounter] = useState(1);

  const guardar = () => {
    form.validateFields().then((data) => {
      setLoading(true);
      API.post("/user/quota", data)
        .then(() => {
          setModalBuyEvent(null);
        })
        .catch((error) => {
          setLoading(false);
          message.error(error.response?.data?.message, 30);
        });
    });
  };

  const ammount = () => {
    if (counter < 200) {
      return counter * 500;
    }
    if (counter < 800) {
      return counter * 300;
    }
    return counter * 150;
  };

  useEffect(() => {
    setCounter(1);
  }, []);

  useEffect(() => {
    if (!modalBuyEvent) {
      setLoading(false);
      setCounter(1);
      if (form) {
        form.setFieldsValue({ events: 1 });
      }
    }
  }, [modalBuyEvent]);

  return (
    <Modal
      visible={modalBuyEvent}
      title="Compra de eventos"
      onCancel={() => (loading ? null : setModalBuyEvent(null))}
      okText="Comprar"
      onOk={guardar}
      destroyOnClose={true}
    >
      <div ref={modal}>
        <Form form={form} layout="vertical">
          <Form.Item
            name="events"
            rules={[
              {
                required: true,
                message: "Especifique cuantos eventos quiere comprar",
              },
            ]}
          >
            <Slider
              getTooltipPopupContainer={() => {
                return modal?.current;
              }}
              included={true}
              min={1}
              max={1000}
              step={1}
              defaultValue={1}
              marks={marks}
              tooltipVisible={true}
              onChange={(value) => setCounter(value)}
            />
          </Form.Item>
        </Form>
      </div>
      <Statistic
        title="Resumen de compra"
        value={ammount()}
        suffix={"$"}
        precision={0}
      />
    </Modal>
  );
};

export default BuyEvents;
