import Ctx from "Context/Ctx";
import API from "Helpers/axiosApi";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { WebPushCtx } from "WebPush";

const { Modal, Spin, Typography } = require("antd");

const wait = (t = 1000) => {
  return new Promise((resolve) => setTimeout(resolve, t));
};

const Logout = () => {
  const [visible, setVisible] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const isLogout = searchParams.get("logout");
  //const { unsubscribe } = useContext(WebPushCtx);
  const { logout } = useContext(Ctx);

  const out = async () => {
    try {
      await wait();
      //await unsubscribe();
      //await API.delete("user/subscription");
      setVisible(false);
      await wait(500);
      logout();
    } catch (e) {
      setVisible(false);
      setSearchParams({});
    }
  };

  useEffect(() => {
    if (isLogout) {
      setVisible(true);
      out();
    }
  }, [isLogout]);

  return (
    <Modal
      visible={visible}
      destroyOnClose={false}
      closable={false}
      footer={null}
    >
      <Spin spinning={true}>
        <Typography.Title style={{ textAlign: "center" }}>
          Cerrando de manera segura...
        </Typography.Title>
      </Spin>
    </Modal>
  );
};

export default Logout;
