export const SOCKET_URL = process.env.REACT_APP_SOCKET;
export const EVENT_STATUS_PENDIENT = 1;
export const EVENT_STATUS_ERROR = 2;
export const EVENT_STATUS_DONE = 3;
export const EVENT_STATUS_INPROGRESS = 4;

export const EVENT_GET_STATUS = 0;
export const EVENT_ACUSE = 1;
export const EVENT_INVOICE = 2;

export const EVENT_INSCRIPCION_TITULO_VALOR = 7;
export const EVENT_ENDOSO_GARANTIA = 8;
export const EVENT_CANCELAR_ENDOSO_GARANTIA = 9;
export const EVENT_ENDOSO_PROPIEDAD = 10;

export const EVENT_ENDOSO_PROCURACION = 11;
export const EVENT_INFORME_PAGO = 12;
export const EVENT_PAGO_FACTURA = 13;
export const EVENT_AVAL_TOTAL = 14;
export const EVENT_MANDATO = 15;
export const EVENT_MANDATO_PODER_GENERAL = 16;
export const EVENT_RECIBO = 19;
export const EVENT_ACEPTACION_EXPRESA = 20;
export const EVENT_MANDATO_ESPECIFICO = 21;

export const EVENT_TRANSFERENCIA_DERECHO_PARCIAL_SIN_RESPONSABILIDAD = 22;
export const EVENT_TRANSFERENCIA_DERECHO_TOTAL_SIN_RESPONSABILIDAD = 23;
export const EVENT_TRANSFERENCIA_DERECHO_PARCIAL_CON_RESPONSABILIDAD = 24;
export const EVENT_TRANSFERENCIA_DERECHO_TOTAL_CON_RESPONSABILIDAD = 25;

export const API_KEY_STATUS_ACTIVE = 1;
export const API_KEY_STATUS_INACTIVE = 0;

export const IDENTIFICATION_TYPE = [
  { value: 11, label: "Registro civil" },
  { value: 12, label: "Tarjeta de identidad" },
  { value: 13, label: "Cédula de ciudadanía" },
  { value: 21, label: "Tarjeta de extranjería" },
  { value: 22, label: "Cédula de extranjería" },
  { value: 31, label: "NIT" },
  { value: 41, label: "Pasaporte" },
  { value: 42, label: "Documento de identificación extranjero" },
  { value: 47, label: "PEP" },
  { value: 50, label: "NIT de otro país" },
  { value: 91, label: "NUIP *" },
];

export const PAYMENT_METHOD = [
  { value: "10", label: "Efectivo" },
  { value: "49", label: "Tarjeta débito" },
  { value: "42", label: "Consignación bancaria" },
  { value: "3", label: "Débito ACH" },
  { value: "25", label: "Cheque certificado" },
  { value: "26", label: "Cheque Local" },
  { value: "24", label: "Nota cambiaria esperando aceptación" },
  { value: "48", label: "Tarjeta Crédito" },
  { value: "47", label: "Transferencia Débito Bancaria" },
  { value: "44", label: "Nota cambiaria" },
  { value: "20", label: "Cheque" },
  { value: "23", label: "Cheque bancario de gerencia" },
  { value: "71", label: "Bonos" },
  { value: "72", label: "Vales" },
  { value: "64", label: "Nota promisoria firmada pro el banco" },
  {
    value: "65",
    label: "Nota promisoria firmada por un banco avalada por otro banco",
  },
  { value: "66", label: "Nota promisoria firmada" },
  {
    value: "67",
    label: "Nota promisoria firmada por un tercero avalada por un banco",
  },
  { value: "60", label: "Nota promisoria" },
  { value: "2", label: " Crédito ACH" },
  { value: "96", label: " Método de pago solicitado no usuado ZZZ Otro*" },
  { value: "91", label: " Nota bancaria transferible" },
  { value: "95", label: " Giro formato abierto" },
  { value: "92", label: "Cheque local transferible" },
  { value: "13", label: " Crédito Ahorro" },
  { value: "93", label: "Giro referenciado" },
  { value: "14", label: " Débito Ahorro" },
  { value: "94", label: "Giro urgente" },
  {
    value: "39",
    label: "Crédito Intercambio Corporativo (CTX)",
  },
  { value: "40", label: "Débito Intercambio Corporativo (CTX)" },
  {
    value: "4",
    label: "Reversión débito de demanda ACH",
  },
  { value: "41", label: "Desembolso Crédito plus (CCD+)" },
  {
    value: "5",
    label: "Reversión crédito de demanda ACH",
  },
  { value: "43", label: "Desembolso Débito plus (CCD+)" },
  {
    value: "6",
    label: "Crédito de demanda ACH",
  },
  { value: "45", label: "Transferencia Crédito Bancario" },
  {
    value: "7",
    label: "Débito de demanda ACH",
  },
  { value: "46", label: "Transferencia Débito Interbancario" },
  { value: "9", label: "Clearing Nacional o Regional" },
  { value: "50", label: "Postgiro" },
  {
    value: "11",
    label: "Reversión Crédito Ahorro",
  },
  { value: "51", label: "Telex estándar bancario" },
  { value: "12", label: "Reversión Débito Ahorro" },
  { value: "52", label: "Pago comercial urgente" },
  { value: "18", label: "Desembolso (CCD) débito" },
  { value: "53", label: "Pago Tesorería Urgente" },
  {
    value: "19",
    label: "Crédito Pago negocio corporativo (CTP)",
  },
  { value: "15", lable: "Bookentry Crédito" },
  { value: "21", label: "Poyecto bancario" },
  { value: "16", label: "Bookentry Débito" },
  {
    value: "22",
    label: "Proyecto bancario certificado",
  },
  { value: "17", label: "Desembolso Crédito (CCD)" },
  {
    value: "27",
    label: "Débito Pago Neogcio Corporativo (CTP)",
  },
  { value: "70", label: "Retiro de nota por el por el acreedor" },
  {
    value: "28",
    label: "Crédito Negocio Intercambio Corporativo (CTX)",
  },
  {
    value: "74",
    label: "Retiro de nota por el por el acreedor sobre un banco",
  },
  {
    value: "29",
    label: "Débito Negocio Intercambio Corporativo (CTX)",
  },
  {
    value: "75",
    label: "Retiro de nota por el acreedor, avalada por otro banco",
  },
  {
    value: "30",
    label: "Transferencia Crédito",
  },
  {
    value: "76",
    label:
      "Retiro de nota por el acreedor, sobre un banco avalada por un tercero",
  },
  { value: "31", label: "Transferencia Débito" },
  {
    value: "77",
    label: "Retiro de una nota por el acreedor sobre un tercero",
  },
  { value: "32", label: "Desembolso Crédito plus (CCD+)" },
  {
    value: "78",
    label:
      "Retiro de una nota por el acreedor sobre un tercero avalada por un banco",
  },
  {
    value: "33",
    label: "Desembolso Débito plus (CCD+)",
  },
  { value: "1", label: "Instrumento no definido" },
  { value: "34", label: "Pago y depósito pre acordado (PPD)" },
  { value: "37", label: "Pago Negocio Corporativo Ahorros Crédito (CTP)" },
  { value: "35", label: "Desembolso Crédito (CCD)" },
  { value: "38", label: "Pago Negocio Corporativo Ahorros Débito (CTP)" },
  { value: "36", label: "Desembolso Débito (CCD)" },
  { value: "97", label: "Clearing entre partners" },
];

export const TOKEN_NAME = "radian-token";

export const STATUS_CODE_NSU_NO_ENCONTRADO = "66";
export const STATUS_CODE_TRACK_ID_NO_ENCONTRADO = "90";
export const STATUS_CODE_ERRORES_CAMPOS_MANDATARIOS = "99";
export const STATUS_CODE_PROCESADO_CORRECTAMENTE = "00";
