import { Layout, Menu, Result } from "antd";
import Ctx from "Context/Ctx";
import { useContext } from "react";
const { Header, Content } = Layout;

const Page = ({ role = [], children }) => {
  const { user } = useContext(Ctx);

  if (!role.length || (role.length && role.includes(user?.role))) {
    return (
      <>
        <Content style={{ margin: "24px 16px 0" }}>
          <div
            className="site-layout-background"
            style={{ padding: 24, minHeight: 360 }}
          >
            {children}
          </div>
        </Content>
      </>
    );
  }
  return (
    <Result
      status="403"
      title="403"
      subTitle="Lo siento, no estas autorizado para acceder a esta página."
    />
  );
};
export default Page;
