import API from "Helpers/axiosApi";
import { useState } from "react";

const { Card, Form, Input, Row, Col, Button, Spin, message } = require("antd");

const PasswordUpdate = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const save = (data) => {
    setLoading(true);
    API.patch("/user/password", data)
      .then(() => {
        form.resetFields();
        message.success("Contraseña actualizada correctamente");
      })
      .catch((error) => message.error(error.response.data.message))
      .finally(() => setLoading(false));
  };

  return (
    <Col xs={24} sm={12} lg={8} xl={6} xxl={4}>
      <Spin spinning={loading}>
        <Card cover={<img src="/ilustraciones/seguridad.png" />}>
          <Form layout="vertical" form={form} onFinish={save}>
            <Form.Item
              label="Contraseña actual"
              name="currentPass"
              rules={[
                {
                  required: true,
                  message: "Ingrese la contraseña que usa actualmente",
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label="Nueva Contraseña"
              name="pass"
              rules={[
                { required: true, message: "Ingrese la nueva contraseña" },
                {
                  validator: (_, v) => {
                    if (v == form.getFieldValue("currentPass")) {
                      return Promise.reject(
                        "Su nueva contraseñas debe ser distinta a la actual"
                      );
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Form.Item
              label="Confirmar nueva contraseña"
              name="pass2"
              rules={[
                {
                  required: true,
                  message: "Confirme la nueva contraseña",
                },
                {
                  validator: (_, v) => {
                    if (v != form.getFieldValue("pass")) {
                      return Promise.reject("Las contraseñas no coinciden");
                    }
                    return Promise.resolve();
                  },
                },
              ]}
            >
              <Input type="password" />
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Actualizar contraseña
            </Button>
          </Form>
        </Card>
      </Spin>
    </Col>
  );
};

export default PasswordUpdate;
