import React from "react";
import "antd/dist/antd.variable.min.css";
import "./App.css";
import es from "antd/es/locale/es_ES";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import Home from "Home";
import Login from "Login";
import Security from "Context/Security";
import ApiList from "Api/List";
import PageNotFound from "PageNotFound";
import { ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/es";
import Register from "Register";
import RecoveryPassword from "RecoveryPassword";
import Profile from "Profile";
import IntegrationPage from "Integration";

moment.locale("es");

const EventList = React.lazy(() => import("Event/List"));
const UserList = React.lazy(() => import("Users/List"));
const MarkerPlace = React.lazy(() => import("MarkerPlace"));

ConfigProvider.config({
  theme: {
    primaryColor: "#65995C",
    secondaryColor: "#1E6898",
  },
});

function App() {
  return (
    <>
      <ConfigProvider locale={es}>
        <Router>
          <Security>
            <Routes>
              <Route path="/login" index element={<Login />}></Route>
              <Route path="/register" index element={<Register />}></Route>
              <Route
                path="/recovery-password"
                index
                element={<RecoveryPassword />}
              ></Route>

              <Route path="/" element={<Home />}>
                <Route
                  path="/profile"
                  exact
                  element={
                    <React.Suspense fallback={<>...</>}>
                      <Profile />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/event"
                  exact
                  element={
                    <React.Suspense fallback={<>...</>}>
                      <EventList />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/api"
                  exact
                  element={
                    <React.Suspense fallback={<>...</>}>
                      <ApiList />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/users"
                  exact
                  element={
                    <React.Suspense fallback={<>...</>}>
                      <UserList />
                    </React.Suspense>
                  }
                />
                <Route
                  path="/marker-place"
                  exact
                  element={
                    <React.Suspense fallback={<>...</>}>
                      <MarkerPlace />
                    </React.Suspense>
                  }
                />
                <Route path="/integration" exact element={
                  <React.Suspense fallback={<>...</>}>
                    <IntegrationPage/>
                  </React.Suspense>
                }/>
                <Route path="/" element={<Navigate to="/event" replace />} />
                <Route path="*" element={<PageNotFound />} />
              </Route>
            </Routes>
          </Security>
        </Router>
      </ConfigProvider>
    </>
  );
}

export default App;
