import React from "react";
import {
  Routes,
  Route,
  Link,
  Outlet,
  useSearchParams,
  Navigate,
} from "react-router-dom";
import {
  UserOutlined,
  ShoppingCartOutlined,
  AppstoreOutlined,
  ApiOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
  TeamOutlined
} from "@ant-design/icons";
import NumericLabel from "react-pretty-numbers";
import { Affix, Layout, Menu, Button, Space, Typography, Tooltip } from "antd";

import { useContext, useEffect, useState } from "react";
import Ctx from "Context/Ctx";
import EventCounter from "Components/EventCounter";
import BuyEvents from "MarkerPlace/Buy";
import UserSession from "Components/UserSession";
import Logout from "Components/Logout";
import { EventProvider } from "Context/Events";
import { SESSION_STATE_INVALID } from "Context/Constant";
import Socket from "Context/Socket";

const { Header, Footer, Sider, Content } = Layout;

const Home = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { page, user, stateSession, quota, setModalBuyEvent } = useContext(Ctx);
  const [selectedKeys, setSelectedKeys] = useState([]);

  useEffect(() => {
    setSelectedKeys([page]);
  }, [page]);

  return (
    <Socket>
      <EventProvider>
        {stateSession == SESSION_STATE_INVALID && <Navigate to="/login" />}
        <Layout theme="light" style={{ minHeight: "100vh" }}>
          <Sider
            id="sider"
            theme="light"
            collapsedWidth="0"
            onBreakpoint={(broken) => {
              console.log(broken);
            }}
            trigger={null}
            collapsible
            collapsed={collapsed}
          >
            <div
              style={{
                display: "flex",
                height: "100%",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <div>
                <div className="logo">
                  <img src="/logo_radtrack.png" />
                </div>


                <Menu mode="inline" theme="light" selectedKeys={selectedKeys}>
                  <Menu.Item key="/event" icon={<AppstoreOutlined />}>
                    <Link to="/event">Eventos</Link>
                  </Menu.Item>

                  {user?.role == 1 && (
                    <>
                      <Menu.Item key="/api" icon={<ApiOutlined />}>
                        <Link to="/api">API</Link>
                      </Menu.Item>
                      <Menu.Item key="/users" icon={<TeamOutlined />}>
                        <Link to="/users">Equipo de trabajo</Link>
                      </Menu.Item>
                    </>
                  )}
                  <Menu.Item key="/integration" icon={<ApiOutlined />}>
                      <Link to="/integration">Integración</Link>
                  </Menu.Item>
                  {user?.role == 2 && (
                    <Menu.Item key="/users" icon={<TeamOutlined />}>
                      <Link to="/users">Usuarios</Link>
                    </Menu.Item>
                  )}
                </Menu>
              </div>
              <Affix offsetBottom={0}>
                {user?.role == 1 && (
                  <Button
                    icon={<ShoppingCartOutlined />}
                    style={{
                      marginTop: 16,
                      display: "block",
                    }}
                    type="primary"
                    block={true}
                    style={{ marginBottom: 16 }}
                    onClick={() => setModalBuyEvent({})}
                  >
                    Comprar eventos
                  </Button>
                )}
                <Typography.Paragraph style={{ textAlign: "center" }}>
                  <a href="https://ufotech.co/" target="blank">
                    © 2022 Ufotech
                  </a>
                </Typography.Paragraph>
              </Affix>
            </div>
          </Sider>
          <Layout className="site-layout" theme="light">
            <Header
              className="site-layout-background"
              style={{
                padding: 0,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  padding: 16,
                  height: "100%",
                }}
              >
                {React.createElement(
                  collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                  {
                    className: "trigger",
                    onClick: () => setCollapsed(!collapsed),
                  }
                )}
                <div
                  style={{
                    flex: 1,
                    justifyContent: "flex-end",
                    alignItems: "center",
                    display: "flex",
                    gap: 16,
                  }}
                >
                  {user?.role != 2 && (
                    <Space direction="vertical" size={2}>
                      {quota}

                      <Tooltip title={`Eventos disponibles`}>
                        <Typography.Text
                          style={{
                            cursor: "pointer",
                            color: "rgb(86 77 77 / 64%)",
                          }}
                        >
                          ED
                        </Typography.Text>
                      </Tooltip>
                    </Space>
                  )}
                  <UserSession />
                </div>
              </div>
            </Header>
            <Content className="site-layout-background">
              <EventCounter />
              <Outlet />
            </Content>
            <Logout />
            <BuyEvents />
          </Layout>
        </Layout>
      </EventProvider>
    </Socket>
  );
};

export default Home;
