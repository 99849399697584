import {
  Form,
  Input,
  Button,
  Spin,
  notification,
  Typography,
  Space,
  message,
  Alert,
} from "antd";
import API from "Helpers/axiosApi";
import { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Ctx from "Context/Ctx";

const plataforma = [
  "OS",
  "OSVersion",
  "Browser",
  "BrowserVersion",
  "DeviceType",
  "DeviceModel",
  "DeviceVendor",
  "Engine",
  "EngineVersion",
  "CPU",
  "UA",
];

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();
  const { login } = useContext(Ctx);
  const [searchParams, setSearchParams] = useSearchParams();
  const msn = searchParams.get("message");
  const alerta = searchParams.get("alert");

  const onFinish = (values) => {
    setLoading(true);
    API.post("/user/login", values)
      .then(({ data }) => {
        login(data.token);
        navigation("/");
      })
      .catch((error) => {
        console.log(error);
        notification.error({ message: error?.response?.data?.message });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (msn) {
      message.info(msn);
      searchParams.delete("message");
      setSearchParams(searchParams);
    }
  }, [msn]);

  useEffect(() => {
    if (alerta) {
      notification.info({ message: alerta, duration: 60 });
      searchParams.delete("alert");
      setSearchParams(searchParams);
    }
  }, [alerta]);

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="bg-login"
    >
      <div className="login-a">
        <img src="/logo_radtrack.png" />
        <Typography.Paragraph style={{ color: "#E5E7E9" }}>
          Registra tus eventos de RADIAN ante la DIAN.
        </Typography.Paragraph>
        <Typography.Paragraph>
          <a
            href="https://ufotech.co/"
            style={{ color: "#E5E7E9" }}
            target="blank"
          >
            © 2022 Ufotech
          </a>
        </Typography.Paragraph>
      </div>
      <div className="login-b">
        <div className="card-login">
          <Spin spinning={loading}>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
            >
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    email: true,
                    message: "por favor ingresa tu email!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Password"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Por favor ingresa tu contraseña!",
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  offset: 8,
                  span: 16,
                }}
              >
                <Button type="primary" htmlType="submit">
                  Acceder
                </Button>
              </Form.Item>
            </Form>

            <div style={{ display: "flex", justifyContent: "center", gap: 16 }}>
              <Link to="/register">Registrarse</Link>
              <Link to="/recovery-password">Recuperar contraseña</Link>
            </div>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Login;
