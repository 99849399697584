import { TOKEN_NAME } from "Constants";
import Ctx from "Context/Ctx";
import API from "Helpers/axiosApi";
import { useContext, useEffect, useState } from "react";

const { Card, Form, Input, Row, Col, Button, message, Spin } = require("antd");

const BasicInformation = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useContext(Ctx);
  const [form] = Form.useForm();

  const save = (data) => {
    if (data.name != user.name) {
      setLoading(true);
      API.patch("user/profile", data)
        .then(({ data }) => {
          localStorage.setItem(TOKEN_NAME, data.token);
        })
        .catch((error) => message.error(error.response.data.message))
        .finally(() => setLoading(false));
    } else {
      message.success("Información actualizada");
    }
  };

  useEffect(() => {
    console.log(user);
    form.setFieldsValue({ name: user.name });
  }, [user]);

  return (
    <Col xs={24} sm={12} lg={8} xl={6} xxl={4}>
      <Spin spinning={loading}>
        <Card cover={<img src="/ilustraciones/personal.png" />}>
          <Form layout="vertical" form={form} onFinish={save}>
            <Form.Item
              label="Nombre"
              name="name"
              rules={[
                { required: true, message: "Ingrese como quiere llamarse" },
              ]}
            >
              <Input />
            </Form.Item>
            <Button htmlType="submit" type="primary">
              Actualizar
            </Button>
          </Form>
        </Card>
      </Spin>
    </Col>
  );
};

export default BasicInformation;
