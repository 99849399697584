import { Button, Form, Input, message, Spin, Typography } from "antd";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import API from "Helpers/axiosApi";

const RecoveryPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigation = useNavigate();

  const send = (data) => {
    setLoading(true);
    API.post("user/recovery-password", data)
      .then(({ data }) => {
        message.success(data.message);
      })
      .catch(({ error }) => {
        message.error(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="bg-login"
    >
      <div className="login-a">
        <img src="/logo-w.png" />
        <Typography.Paragraph style={{ color: "#E5E7E9" }}>
          Registra tus eventos de RADIAN ante la DIAN.
        </Typography.Paragraph>
        <Typography.Paragraph>
          <a
            href="https://ufotech.co/"
            style={{ color: "#E5E7E9" }}
            target="blank"
          >
            © 2022 Ufotech
          </a>
        </Typography.Paragraph>
      </div>
      <div className="login-b">
        <Spin spinning={loading}>
          <Form layout="vertical" onFinish={send}>
            <Form.Item
              label="email"
              name="email"
              rules={[
                { required: true, message: "Ingrese su email" },
                {
                  validator: (e, v) => {
                    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(v)) {
                      return Promise.resolve();
                    }
                    return Promise.reject("Ingrese un email valido");
                  },
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Button type="primary" htmlType="submit">
              Recuperar contraseña
            </Button>
          </Form>
        </Spin>
      </div>
    </div>
  );
};
export default RecoveryPassword;
