import { Row, Space, Typography,Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { ReactTitle } from "react-meta-tags";
import BasicInformation from "./BasicInformation";
import PasswordUpdate from "./PasswordUpdate";

const { default: Page } = require("Page");

const Profile = () => {
  return (
    <Page>
      <ReactTitle title="Perfil en RADIAN" />
      <Space size={16} style={{ marginBottom: 32 }}>
        <UserOutlined size={32} />
        <Typography.Title level={4} style={{ margin: 0 }}>
          Mi zona personal
        </Typography.Title>
      </Space>

      <Row gutter={[16, 16]} justify="center">
        <BasicInformation />
        <PasswordUpdate />
        
      </Row>

  
    </Page>
  );
};

export default Profile;
