import { Button, Card, Col, Form, Input, Row, Space, Spin, Typography, Upload, notification } from "antd"
import {ApiOutlined,PlusOutlined} from "@ant-design/icons"
import API from "Helpers/axiosApi";
import { useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";

const Integration=()=>{
    const [form] = useForm()
    const [loading,setLoading] = useState(false)

    const load=async ()=>{
        try{
            setLoading(true)
            const {data} = await API('integration')
            if(data){
                form.setFieldsValue(data)
            }
        }catch(error){
            notification.open({
                type:'error',
                message:error?.data?.message || error.toString()
            })
        }finally{
            setLoading(false)
        }
    }

    const save=async (payload)=>{
        
        try{
            setLoading(true)
            const {data} = await API({
                url:'integration',
                method:'post',
                data:payload
            })
            notification.open({
                type:'success',
                message:data?.message
            })
        }catch(error){
            notification.open({
                type:'error',
                message:error?.data?.message || error.toString()
            })
        }finally{
            setLoading(false)
        }
    }

    useEffect(()=>{
        load()
    },[])

    return <Spin spinning={loading}>
            <Card>
            <center>
                <ApiOutlined style={{fontSize:96,color:'#D7DBDD'}}/>
                <Typography.Paragraph>Datos de integración</Typography.Paragraph>
            </center>
            
                <Form form={form} layout="vertical" onFinish={save}>
                
                <Form.Item style={{flex:1}} label="Software Security Code P1" name="p1" rules={[{required:true,message:'Campo requerido'}]}>
                        <Input style={{width:'100%'}} />
                    </Form.Item>
                    <Form.Item label="Key" name="p2" rules={[{required:true,message:'Campo requerido'}]}>
                        <Input/>
                    </Form.Item>
                
                    <Form.Item label="Llave de facturación" name="billing_key" rules={[{required:true,message:'Campo requerido'}]}>
                        <Input/>
                    </Form.Item>
                    <Form.Item label="Razon social" name="bussines_name" rules={[{required:true,message:'Campo requerido'}]}>
                        <Input/>
                    </Form.Item>
                    <Row gutter={[16]}>
                        <Col xs={24} sm={8}>
                            <Form.Item label="DV" name="dv" rules={[{required:true,message:'Campo requerido'}]}>
                                <Input/>
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={16}>
                            <Form.Item label="NIT" name="nit" rules={[{required:true,message:'Campo requerido'}]}>
                                <Input style={{width:'100%'}}/>
                            </Form.Item>
                        </Col>
                    </Row>
                    
                    
                    <Row gutter={[16]}>
                        <Col xs={12}>
                            <Form.Item label="Pais" name="country" rules={[{required:true,message:'Campo requerido'}]}>
                                <Input placeholder="Colombia"/>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item label="Código Pais" name="country_code" rules={[{required:true,message:'Campo requerido'}]}>
                                <Input placeholder="CO"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16]}>
                        <Col xs={12}>
                            <Form.Item label="Departamento" name="departament" rules={[{required:true,message:'Campo requerido'}]}>
                                <Input placeholder="Atlantico"/>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item label="Código Departamento" name="departament_code" rules={[{required:true,message:'Campo requerido'}]}>
                                <Input placeholder="08"/>
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={[16]}>
                        <Col xs={12}>
                            <Form.Item label="Ciudad" name="city" rules={[{required:true,message:'Campo requerido'}]}>
                                <Input placeholder="Barranquilla"/>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item label="Código Postal" name="location_cp" rules={[{required:true,message:'Campo requerido'}]}>
                                <Input placeholder="080001"/>
                            </Form.Item>
                        </Col>
                        <Col xs={12}>
                            <Form.Item label="Dirección" name="direction" rules={[{required:true,message:'Campo requerido'}]}>
                                <Input placeholder="Carrera 51"/>
                            </Form.Item>
                        </Col>
                    </Row>

                
                    <Button type="primary" htmlType="submit">Guardar</Button>
                </Form>
        </Card>
       </Spin>
}

export default Integration