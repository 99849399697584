import React, { useEffect, useState } from "react";
import { Card, Avatar, Typography, Dropdown, Menu, Space } from "antd";
import { UserOutlined, SmileOutlined, LogoutOutlined } from "@ant-design/icons";
import { useJwt } from "react-jwt";
import { TOKEN_NAME } from "Constants";
import { useSearchParams, useNavigate } from "react-router-dom";

const role = (rol) => {
  if (rol == 2) {
    return "Admin";
  }
  if (rol == 1) {
    return "Owner";
  }
  if (rol == 3) {
    return "user";
  }
};
const UserSession = () => {
  const { decodedToken, isExpired } = useJwt(localStorage.getItem(TOKEN_NAME));
  let [_, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const menu = (
    <Menu>
      <Menu.Item
        key={0}
        icon={<UserOutlined />}
        onClick={() => navigate("/profile")}
      >
        Perfil
      </Menu.Item>
      <Menu.Item
        key={1}
        icon={<LogoutOutlined />}
        onClick={() => setSearchParams({ logout: true })}
      >
        Cerrar sesión
      </Menu.Item>
    </Menu>
  );

  return (
    <Dropdown overlay={menu}>
      <Space>
        <Space direction="vertical" size={2}>
          <Typography.Text ellipsis>{decodedToken?.name}</Typography.Text>
          <Typography.Text style={{ color: "rgb(86 77 77 / 64%)" }}>
            {role(decodedToken?.role)}
          </Typography.Text>
        </Space>
        <Avatar className="user-avatar" size={42} icon={<UserOutlined />} />
      </Space>
    </Dropdown>
  );
};

export default UserSession;
