import API from "Helpers/axiosApi";
import Page from "Page";
import {
  Breadcrumb,
  Space,
  Button,
  Table,
  Typography,
  Switch,
  message,
  Popconfirm,
  Alert,
} from "antd";
import { useEffect, useState } from "react";
import { PlusOutlined, SyncOutlined } from "@ant-design/icons";
import { DATE_FORMAT_TIME } from "Helpers/Constants";
import moment from "moment";
import { API_KEY_STATUS_ACTIVE, API_KEY_STATUS_INACTIVE } from "Constants";
import { ReactTitle } from "react-meta-tags";

const Status = ({ status, _id, onChange }) => {
  const [loading, setLoading] = useState(false);

  const toggle = (checked) => {
    setLoading(true);
    API.patch("api-key", {
      _id: _id,
      status: checked ? API_KEY_STATUS_ACTIVE : API_KEY_STATUS_INACTIVE,
    })
      .then(({ data }) => {
        onChange(data);
      })
      .finally(() => setLoading(false))
      .catch((error) => {
        message.error(error?.response?.data?.message);
      });
  };

  return (
    <Switch
      loading={loading}
      checked={status == API_KEY_STATUS_ACTIVE}
      onChange={(checked) => toggle(checked)}
    />
  );
};

const ApiList = () => {
  const [apis, setApis] = useState([]);
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);

  const load = () => {
    setLoading(true);
    API("api-key")
      .then(({ data }) => setApis(data))
      .finally(() => setLoading(false));
  };

  const create = () => {
    setCreating(true);
    API.post("api-key")
      .then(({ data }) => {
        setApis([data, ...apis]);
        message.success("Esta API key es privada,no debe ser compartida.", 30);
      })
      .catch((error) => {
        message.error(error?.response?.data?.message);
      })
      .finally(() => setCreating(false));
  };

  const onChange = (api) => {
    setApis(
      apis.map((a) => {
        if (a._id == api._id) {
          return api;
        }
        return a;
      })
    );
  };

  useEffect(() => {
    load();
  }, []);

  return (
    <Page role={[1]}>
      <ReactTitle title="Administrar API RADIAN" />
      <Space align="baseline">
        <Breadcrumb>
          <Breadcrumb.Item active="true">API</Breadcrumb.Item>
        </Breadcrumb>

        <Button
          color="primary"
          disabled={loading}
          icon={<SyncOutlined />}
          onClick={load}
        ></Button>
        <Popconfirm
          onConfirm={create}
          title="Estas a punto de crear un API key"
          okText="Crear"
          cancelText="cancelar"
        >
          <Button
            type="primary"
            icon={<PlusOutlined />}
            disabled={loading || creating}
            loading={creating}
          ></Button>
        </Popconfirm>
      </Space>

      <div style={{ marginTop: 24 }}>
        <Typography.Paragraph>
          Los tokens de acceso personal funcionan como un nombre y una
          contraseña combinados para la autenticación API. Genere un token para
          acceder a la API de RADIAN desde tú propio sistema.
        </Typography.Paragraph>
      </div>

      <Table
        style={{ marginTop: 32 }}
        dataSource={apis}
        loading={loading}
        rowKey="_id"
        pagination={false}
      >
        <Table.Column
          title="Fecha creación"
          dataIndex="createdAt"
          render={(createdAt) => moment(createdAt).format(DATE_FORMAT_TIME)}
        />
        <Table.Column
          title="Token"
          dataIndex="key"
          render={(key) => (
            <Typography.Text code copyable ellipsis style={{ width: 200 }}>
              {key}
            </Typography.Text>
          )}
        />
        <Table.Column
          title="Activa"
          render={(doc) => <Status {...doc} onChange={onChange} />}
        />
      </Table>
    </Page>
  );
};

export default ApiList;
