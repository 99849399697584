import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined,
  SyncOutlined,
} from "@ant-design/icons";

export const INVOICE_INVALID = -1;
export const INVOICE_VALIDATING = 0;
export const INVOICE_VALID = 1;

export const EVENT_STATUS_PENDIENT = 1;
export const EVENT_STATUS_ERROR = 2;
export const EVENT_STATUS_DONE = 3;
export const EVENT_STATUS_INPROGRESS = 4;

export const EVENT_STATUS = {
  [EVENT_STATUS_PENDIENT]: {
    name: "Pendiente",
    color: "#A6ACAF",
    icon: <ClockCircleOutlined />,
  },
  [EVENT_STATUS_ERROR]: {
    name: "Error",
    color: "#E74C3C",
    icon: <ExclamationCircleOutlined />,
  },
  [EVENT_STATUS_DONE]: {
    name: "Procesado",
    color: "#65995C",
    icon: <CheckCircleOutlined />,
  },
  [EVENT_STATUS_INPROGRESS]: {
    name: "En progreso...",
    color: "orange",
    icon: <SyncOutlined spin />,
  },
};

export const EVENT_GET_STATUS = 0;
export const EVENT_ACUSE = 1;
export const EVENT_INVOICE = 2;

export const EVENT_INSCRIPCION_TITULO_VALOR = 7;
export const EVENT_ENDOSO_GARANTIA = 8;
export const EVENT_CANCELAR_ENDOSO_GARANTIA = 9;
export const EVENT_ENDOSO_PROPIEDAD = 10;

export const EVENT_ENDOSO_PROCURACION = 11;
export const EVENT_INFORME_PAGO = 12;
export const EVENT_PAGO_FACTURA = 13;
export const EVENT_AVAL_TOTAL = 14;
export const EVENT_MANDATO = 15;
export const EVENT_MANDATO_PODER_GENERAL = 16;
export const EVENT_MANDATO_ESPECIFICO = 21;
export const EVENT_RECIBO = 19;
export const EVENT_ACEPTACION_EXPRESA = 20;
export const EVENT_TRANSFERENCIA_DERECHO_PARCIAL_SIN_RESPONSABILIDAD = 22
export const EVENT_TRANSFERENCIA_DERECHO_TOTAL_SIN_RESPONSABILIDAD = 23
export const EVENT_TRANSFERENCIA_DERECHO_PARCIAL_CON_RESPONSABILIDAD = 24
export const EVENT_TRANSFERENCIA_DERECHO_TOTAL_CON_RESPONSABILIDAD = 25
export const EVENT_XML_FACTURA=26

export const EVENT_TYPES = {
  [EVENT_TRANSFERENCIA_DERECHO_PARCIAL_SIN_RESPONSABILIDAD]:{name:"Trans. parcial sin responsabilidad"},
  [EVENT_TRANSFERENCIA_DERECHO_TOTAL_SIN_RESPONSABILIDAD]:{name:"Trans. total sin responsabilidad"},
  [EVENT_TRANSFERENCIA_DERECHO_PARCIAL_CON_RESPONSABILIDAD]:{name:"Trans. parcial con responsabilidad"},
  [EVENT_TRANSFERENCIA_DERECHO_TOTAL_CON_RESPONSABILIDAD]:{name:"Trans. total con responsabilidad"},
  [EVENT_GET_STATUS]: { name: "Histórico" },
  [EVENT_ACUSE]: { name: "Acuse" },
  [EVENT_INVOICE]: { name: "Factura" },
  [EVENT_INSCRIPCION_TITULO_VALOR]: { name: "Inscripción título valor" },
  [EVENT_ENDOSO_GARANTIA]: { name: "Endoso garantía" },
  [EVENT_CANCELAR_ENDOSO_GARANTIA]: { name: "Cancelar endoso garantía" },
  [EVENT_ENDOSO_PROPIEDAD]: { name: "Endoso propiedad" },
  [EVENT_ENDOSO_PROCURACION]: { name: "Endoso procuración" },
  [EVENT_INFORME_PAGO]: { name: "Informe pago" },
  [EVENT_PAGO_FACTURA]: { name: "Pago factura" },
  [EVENT_AVAL_TOTAL]: { name: "Aval total" },
  [EVENT_MANDATO]: { name: "Mandato" },
  [EVENT_MANDATO_PODER_GENERAL]: { name: "Poder general" },
  [EVENT_MANDATO_ESPECIFICO]: { name: "Mandato específico" },
  [EVENT_RECIBO]: { name: "Recibo" },
  [EVENT_ACEPTACION_EXPRESA]: { name: "Aceptación expresa" },
  [EVENT_XML_FACTURA]:{name:"XML factura"}
};

export const DATE_FORMAT = "YYYY/MM/DD";
export const DATE_FORMAT_TIME = "YYYY/MM/DD hh:mm a";
