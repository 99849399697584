import { Statistic, Button, Card, Typography, Alert, notification } from "antd";
import { ShoppingCartOutlined } from "@ant-design/icons";
import { useContext, useEffect, useState } from "react";
import Ctx from "Context/Ctx";
import API from "Helpers/axiosApi";
import { useJwt } from "react-jwt";
import { TOKEN_NAME } from "Constants";
import { presetPrimaryColors } from "@ant-design/colors";
import NumericLabel from "react-pretty-numbers";
import { SocketCtx } from "Context/Socket";

const EventCounter = () => {
  const { modalBuyEvent, setModalBuyEvent, user, quota, setQuota } =
    useContext(Ctx);
  const { socketEvent } = useContext(SocketCtx);

  const load = () => {
    API.get("/user/event-available").then(({ data }) => {
      setQuota(data.events);
    });
  };

  useEffect(() => {
    load();
  }, []);

  useEffect(() => {
    if (socketEvent.action == "quota") {
      notification.info({
        message: "Eventos disponibles",
        description: socketEvent.quota,
        duration: 10,
      });
      setQuota(socketEvent.quota);
    }
  }, [socketEvent]);

  if (user?.role != 1) {
    return null;
  }

  return null;
};

export default EventCounter;
