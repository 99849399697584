import { Button, Form, Input, message, Spin } from "antd";
import Password from "antd/lib/input/Password";
import API from "Helpers/axiosApi";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const Register = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const guardar = (data) => {
    setLoading(true);
    API.post("user/register", data)
      .then(() => {
        message.success(
          "Registro completado, revisa tu email para activar tu cuenta",
          5000
        );
        navigate("/login");
      })
      .catch((error) => {
        message.error(error.response.data.message);
      })
      .finally(() => setLoading(false));
  };

  return (
    <div
      style={{
        width: "100vw",
        height: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      className="bg-login"
    >
      <div className="login-a"></div>

      <div className="login-b">
        <div style={{ padding: 24 }}>
          <img
            src="/logo_radtrack.png"
            style={{ width: "40%", display: "block", marginBottom: 64 }}
          />
          <Spin spinning={loading}>
            <Form name="basic" onFinish={guardar} layout="vertical" form={form}>
              <Form.Item
                label="Razón social"
                name="name"
                rules={[{ required: true, message: "Campo requerido" }]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    email: true,
                    message: "por favor ingresa una contraseña segura!",
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label="Contraseña"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "por favor ingresa una contraseña segura!",
                  },
                ]}
              >
                <Password />
              </Form.Item>

              <Form.Item
                label="Confirmar contraseña"
                name="password2"
                rules={[
                  {
                    required: true,
                    message: "por favor confirme su contraseña!",
                  },
                  {
                    validator: async (_, v) => {
                      console.log(form.getFieldValue("password"));
                      if (form.getFieldValue("password") != v) {
                        return Promise.reject("Las contraseñas no coinciden");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Password />
              </Form.Item>

              <Button
                type="primary"
                htmlType="submit"
                style={{ marginTop: 32 }}
                block
              >
                Registrarse
              </Button>
            </Form>
          </Spin>
        </div>
      </div>
    </div>
  );
};

export default Register;
